const firebaseConfig = {
  projectId: "the-ravello-jllsq",
  appId: "1:112519536121:web:768628ff3bf30025f93128",
  databaseURL:
    "https://the-ravello-jllsq-default-rtdb.europe-west1.firebasedatabase.app",
  storageBucket: "the-ravello-jllsq.appspot.com",
  locationId: "europe-west",
  apiKey: "AIzaSyA4EnGbYBGoWJuaq8vT1r_9GOCCzXx2okE",
  authDomain: "the-ravello-jllsq.firebaseapp.com",
  messagingSenderId: "112519536121",
  measurementId: "G-VGP8MT78J2",
};

export default firebaseConfig;
